import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatIconModule} from '@angular/material/icon';
import {
  createParamById,
  QUESTION_TYPE,
  QuestionWithComputedPropertiesWithAnswer,
  QuizTest,
  QuizTestProgress,
  QuizzesEngineStore,
  TakeQuizResult,
  URL_PROPS
} from '@gigasoftware/evolving-cognition/domain';
import {UrlParams} from '@gigasoftware/shared/domain';
import {
  DlcBlobImageDirective,
  DlcFooterBarComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {BehaviorSubject, Observable, Subject, switchMap} from 'rxjs';
import {filter, take} from 'rxjs/operators';

import {EcAnswerMultipleChoiceComponent} from './ec-answers/ec-answer-multiple-choice/ec-answer-multiple-choice.component';
import {EcAnswerTrueFalseComponent} from './ec-answers/ec-answer-true-false/ec-answer-true-false.component';
import {EcQuizTestHeaderComponent} from './ec-quiz-test-header/ec-quiz-test-header.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-test dlc-global-mobile-page'
  },
  imports: [
    CommonModule,
    EcAnswerTrueFalseComponent,
    EcAnswerMultipleChoiceComponent,
    MatIconModule,
    EcQuizTestHeaderComponent,
    DlcRoundedTextIconButtonComponent,
    DlcFooterBarComponent,
    DlcBlobImageDirective
  ],
  providers: [],
  selector: 'ec-test',
  styleUrls: ['./ec-quiz-test.component.scss'],
  templateUrl: './ec-quiz-test.component.html'
})
export class EcQuizTestComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();

  private quizStore: QuizzesEngineStore = inject(QuizzesEngineStore);

  _quizTest$: BehaviorSubject<QuizTest | null> =
    new BehaviorSubject<QuizTest | null>(null);
  quizTest$: Observable<QuizTest> = <Observable<QuizTest>>(
    this._quizTest$.asObservable().pipe(
      filter((quizTest: QuizTest | null) => {
        return quizTest !== null && quizTest !== undefined;
      })
    )
  );
  averageTimePerQuestionSeconds: Signal<number> = <Signal<number>>(
    toSignal(
      this.quizTest$.pipe(
        switchMap(
          (quizTest: QuizTest) => quizTest.averageTimePerQuestionSeconds$
        )
      )
    )
  );

  currentPctScore: Signal<number> = <Signal<number>>(
    toSignal(
      this.quizTest$.pipe(
        switchMap((quizTest: QuizTest) => quizTest.currentPctScore$)
      )
    )
  );

  currentQuestion: Signal<
    QuestionWithComputedPropertiesWithAnswer | undefined | null
  > = <Signal<QuestionWithComputedPropertiesWithAnswer | undefined | null>>(
    toSignal(
      this.quizTest$.pipe(
        switchMap((quizTest: QuizTest) => quizTest.currentQuestion$)
      )
    )
  );

  progress: Signal<QuizTestProgress> = <Signal<QuizTestProgress>>(
    toSignal(
      this.quizTest$.pipe(switchMap((quizTest: QuizTest) => quizTest.progress$))
    )
  );

  questionType = QUESTION_TYPE;
  quizTest: Signal<QuizTest> = <Signal<QuizTest>>toSignal(this.quizTest$);
  result: Signal<TakeQuizResult | undefined> = <
    Signal<TakeQuizResult | undefined>
  >toSignal(
    this.quizTest$.pipe(switchMap((quizTest: QuizTest) => quizTest.result$))
  );

  @Output() reviewAnswers: EventEmitter<UrlParams> = new EventEmitter();

  @Output() seeAllGrades: EventEmitter<any> = new EventEmitter<any>();

  showTestResults: Signal<boolean> = <Signal<boolean>>(
    toSignal(
      this.quizTest$.pipe(
        switchMap((quizTest: QuizTest) => quizTest.showTestResults$)
      )
    )
  );

  @Output() stopQuiz: EventEmitter<any> = new EventEmitter<any>();

  totalCorrect: Signal<number> = <Signal<number>>(
    toSignal(
      this.quizTest$.pipe(
        switchMap((quizTest: QuizTest) => quizTest.totalCorrect$)
      )
    )
  );

  totalWrong: Signal<number> = <Signal<number>>(
    toSignal(
      this.quizTest$.pipe(
        switchMap((quizTest: QuizTest) => quizTest.totalWrong$)
      )
    )
  );

  private createTest() {
    this.quizStore
      .createTest$()
      .pipe(take(1))
      .subscribe({
        next: (quizTest: QuizTest | null) => {
          if (quizTest) {
            this._quizTest$.next(quizTest);
          }
        }
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    if (this.quizTest()) {
      this.quizTest().onDestroy();
    }
  }

  ngOnInit() {
    this.createTest();
  }

  onReviewAnswers() {
    const result: TakeQuizResult | undefined = this.result();

    if (result) {
      this.reviewAnswers.emit(
        createParamById(result, URL_PROPS.QUIZ_REVIEW_ID, 'id')
      );

      // this.urlRouteParams.navigateWithParams(
      //   NAV.MOBILE_QUIZ_REVIEW,
      //   createParamById(result, URL_PROPS.QUIZ_REVIEW_ID, 'id')
      // );
    }
  }

  onSeeAllGrades() {
    this.seeAllGrades.emit();
    // this.urlRouteParams.navigate(NAV.MOBILE_QUIZ_GRADES_PAGE);
  }

  onTryAgain() {
    if (this.quizTest()) {
      this.quizTest().onDestroy();
    }

    /**
     * QUIZ TEST ID CREATED AT libs/evolving-cognition/domain/src/lib/+quizzes/quiz.fns.ts:129
     */
    this.createTest();
  }
}
