import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  BaseEntity,
  combinePublishedQuestionsAndPath,
  convertToUnixTimestamp,
  createEntityUserArchitecture,
  createOwnerUserRole,
  firestorePublishedQuizDoc,
  firestoreQuizQuestionsByEntity,
  getImagePathsFromQuizAndQuestions,
  Question,
  QuestionWithPath,
  Quiz,
  QuizService,
  updateImagePathWithID
} from '@gigasoftware/evolving-cognition/domain';
import {CopyAsset, CopyAssetToPathParams} from '@gigasoftware/shared/entities';
import {GsAssetService} from '@gigasoftware/shared/media';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {uuid} from '@ngpat/fn';
import {NgPatProcessQueue} from '@ngpat/utils';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {take} from 'rxjs/operators';

export interface ConfirmPublishQuiz {
  quiz: Quiz;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-publish-quiz'
    },
    selector: 'ec-publish-quiz',
    styleUrl: './ec-publish-quiz.component.scss',
    templateUrl: './ec-publish-quiz.component.html',
    standalone: false
})
export class EcPublishQuizComponent implements OnInit {
  acceptedTerms = false;

  isCopying: WritableSignal<boolean> = signal(false);
  copyingQuiz: WritableSignal<string> = signal('');

  firestoreAssetPathProcessQueue: NgPatProcessQueue<CopyAssetToPathParams> =
    new NgPatProcessQueue<CopyAssetToPathParams>();

  imagePathsToCopy: CopyAsset[] = [];

  constructor(
    public dialogRef: MatDialogRef<EcPublishQuizComponent>,
    private store: Store,
    private firestore: NgPatFirestoreService,
    private assetService: GsAssetService,
    private cd: ChangeDetectorRef,
    private quizService: QuizService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPublishQuiz
  ) {}

  ngOnInit() {
    this.firestoreAssetPathProcessQueue.currentItem$.subscribe(
      async (item: CopyAssetToPathParams) => {
        const replace = new RegExp(<string>item.oldID, 'g');

        // console.log('item', item);

        const list = await this.assetService.getDirectoryList(item.oldBasePath);

        // console.log(list);

        list.forEach((path: string) => {
          this.imagePathsToCopy.push({
            newAssetImagePath: path.replace(replace, item.newID),
            oldAssetImagePath: path
          });
        });
        this.firestoreAssetPathProcessQueue.next();
      }
    );
  }

  async onPublish() {
    const that = this;
    if (this.acceptedTerms) {
      this.firestore.user$.pipe(take(1)).subscribe(async (user: User) => {
        const uid = user.uid;

        /**
         * UPDATE UI VIEW TO LET USER KNOW
         * QUIZ, QUESTIONS, AND ASSETS ARE
         * BEING COPIED
         */

        const copyQuizToMessage = `Publishing ${that.data.quiz.name}`;

        this.copyingQuiz.set(copyQuizToMessage);
        this.isCopying.set(true);

        /**
         * SOURCE
         */

        const questionsFirestoreSourcePath = firestoreQuizQuestionsByEntity(
          that.data.quiz as BaseEntity,
          uid
        );

        const sourceQuestions: Question[] = await this.firestore.collectionData(
          questionsFirestoreSourcePath
        );

        /**
         * CREATE NEW QUIZ AND QUESTIONS
         * WITH NEW ID
         */
        const newQuiz: Quiz = {
          ...that.data.quiz,
          id: uuid(),
          ...createEntityUserArchitecture(createOwnerUserRole(uid)),
          publisherUID: uid,
          totalQuestions: sourceQuestions.length,
          unixTimestamp: convertToUnixTimestamp(that.data.quiz.updatedAt)
        };

        newQuiz.isCollaborative = false;
        newQuiz.parentEntityID = null;
        newQuiz.parentEntityType = null;

        newQuiz.isDefault = false;
        newQuiz.isPrivate = false;
        newQuiz.isPublished = true;
        newQuiz.assigned = true;

        const newQuestions: Question[] = sourceQuestions.map((q: Question) => {
          return {
            ...q,
            id: uuid(),
            quizID: newQuiz.id
          };
        });

        const firestoreDestinationPath = firestorePublishedQuizDoc(newQuiz.id);

        /**
         * NOTE: created after quiz params are updated
         */
        const newQuestionsWithFirestorePath: QuestionWithPath[] =
          combinePublishedQuestionsAndPath(newQuestions, newQuiz.id);

        const firestoreAssetsToCopy: CopyAssetToPathParams[] =
          getImagePathsFromQuizAndQuestions([newQuiz, ...newQuestions]);

        if (firestoreAssetsToCopy.length === 0) {
          that.setQuizAndQuestions(
            firestoreDestinationPath,
            newQuiz,
            newQuestionsWithFirestorePath
          );
        } else {
          that.firestoreAssetPathProcessQueue.addItems(
            firestoreAssetsToCopy,
            () => {
              that.setQuizAndQuestions(
                firestoreDestinationPath,
                newQuiz,
                newQuestionsWithFirestorePath
              );
            }
          );
        }
      });
    }
  }

  async setQuizAndQuestions(
    firestoreDestinationPath: string,
    newQuiz: Quiz,
    newQuestions: QuestionWithPath[]
  ) {
    newQuiz = updateImagePathWithID<Quiz>(newQuiz);
    newQuestions = newQuestions.map((q: QuestionWithPath) => {
      return {
        ...q,
        question: updateImagePathWithID<Question>(q.question)
      };
    });

    // console.log('copying image assets', this.imagePathsToCopy);
    // console.log('firestoreDestinationPath', firestoreDestinationPath);
    // console.log('newQuiz', newQuiz);
    // console.log('newQuestions', newQuestions);

    if (this.imagePathsToCopy.length > 0) {
      await this.assetService.copyImageAssets(this.imagePathsToCopy);
    }

    await this.quizService.setQuizAndQuestions(
      firestoreDestinationPath,
      newQuiz,
      newQuestions
    );

    this.isCopying.set(false);
    this.dialogRef.close();
  }
}
