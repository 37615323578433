import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  EvolutionCognitionEnvironment,
  firestoreStudyGroupDoc,
  selectStudyGroupsIoOwn,
  StudyGroup
} from '@gigasoftware/evolving-cognition/domain';
import {ENVIRONMENT, LOGOUT_REDIRECT, LogoutRedirect} from '@gigasoftware/shared/models';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {User} from 'firebase/auth';
import {WINDOW} from '@ngpat/utils';
import {firestoreUserAccountDoc, NgPatFirestoreService} from '@ngpat/firebase';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

export enum DELETE_ACCOUNT_TYPE {
  QUIZKIT_APP = 'quizkit_app', // ios or android
  BROWSER = 'browser'
}

@Component({
    selector: 'ec-delete-account-dialog',
    templateUrl: './pat-delete-account-dialog.component.html',
    styleUrls: ['./pat-delete-account-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, NgIf, MatButtonModule, MatProgressBarModule]
})
export class PatDeleteAccountDialogComponent {
  acctType = DELETE_ACCOUNT_TYPE;

  showCheckoutProgress = false;

  constructor(
    private _dialog: MatDialogRef<PatDeleteAccountDialogComponent>,
    private _store: Store,
    private _firestore: NgPatFirestoreService,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    @Inject(WINDOW) private _win: Window,
    @Inject(ENVIRONMENT) private _env: EvolutionCognitionEnvironment,
    @Inject(LOGOUT_REDIRECT) private _redirect: LogoutRedirect,
    @Inject(MAT_DIALOG_DATA) public data: DELETE_ACCOUNT_TYPE
  ) {}

  onDeleteAccount() {
    const that = this;
    this.showCheckoutProgress = true;
    this._cd.detectChanges();

    this._deleteStudyGroups$()
      .pipe(
        tap(() => {
          // console.log('study groups deleted');
        }),
        switchMap(() =>
          this._deleteAccount().pipe(switchMap(() => that._firestore.logoutFirebase$()))
        )
      )
      .subscribe((_: boolean) => {
        this._router.navigate(this._redirect.logoutRedirect);
        this.showCheckoutProgress = false;
        this._cd.detectChanges();
        this._win.location.reload();
        this._dialog.close();
      });
  }

  private _deleteAccount() {
    return this._firestore.user$.pipe(
      take(1),
      map((user: User) => user.uid),
      switchMap((uid: string | null) => {
        if (uid && uid.length) {
          return this._firestore.recursiveDelete$(firestoreUserAccountDoc(uid));
        }

        return of(true);
      })
    );
  }

  private _deleteStudyGroups$() {
    const that = this;
    return this._store.pipe(
      select(selectStudyGroupsIoOwn),
      map((sgs: StudyGroup[]) => sgs.map((s: StudyGroup) => firestoreStudyGroupDoc(s.id))),
      switchMap((paths: string[]) => {
        // console.log('Study group paths to delete:', paths);
        if (paths && paths.length) {
          const toDelete: Observable<boolean>[] = paths.map((path: string) => {
            return that._firestore.recursiveDelete$(path);
          });

          return forkJoin(toDelete);
        }

        return of(true);
      })
    );
  }
}
