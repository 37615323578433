import {NgIf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input, OnInit,
  Output,
  Signal,
  signal,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ThemePalette} from '@angular/material/core';
import {RectangleDimensions, scaleRectangle} from '@ngpat/calculations';

const numberTransform = {
  transform: (v: number | null | undefined): number => {
    if (v === null || v === undefined) {
      return 0;
    }
    return v;
  }
};

@Component({
    selector: 'ec-logo',
    templateUrl: './ec-logo.component.html',
    styleUrls: ['./ec-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-logo',
        '[class.mat-primary]': 'color === "primary"',
        '[class.mat-accent]': 'color === "accent"',
        '[class.mat-warn]': 'color === "warn"',
        '[class.ec-border]': 'border',
        '[style.width.px]': 'computedWidth()'
        // '[style.min-width.px]': 'computedWidth()'
    },
    imports: [NgIf, MatButtonModule]
})
export class EcLogoComponent implements OnInit {
  sigWidth = signal(42);
  sigHeight = signal(32);

  @Input(numberTransform)
  set width(v: number) {
    this.sigWidth.set(v);
  }

  @Input(numberTransform)
  set height(v: number) {
    this.sigHeight.set(v);
  }

  @Input()
  border = true;

  @Input() size: 'mobile' | 'marketing' = 'mobile';


  @Input() color: ThemePalette | undefined;

  @Output() clickAction: EventEmitter<any> = new EventEmitter<any>();

  computedDimensions: Signal<RectangleDimensions> = computed(() => {
    return scaleRectangle(28, 22, this.sigWidth(), this.sigHeight());
  });

  computedWidth: Signal<number> = computed(() => {
    return this.computedDimensions().width;
  });

  computedHeight: Signal<number> = computed(() => {
    return this.computedDimensions().height;
  });

  ngOnInit() {
    if (this.size === 'mobile') {
      this.sigWidth.set(42);
      this.sigHeight.set(32);
    }

    if (this.size === 'marketing') {
      this.sigWidth.set(58);
      this.sigHeight.set(44);
    }
  }
}
