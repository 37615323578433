import {NgForOf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {
  ClassGradesService,
  Classroom,
  StudentGradesTableData
} from '@gigasoftware/evolving-cognition/domain';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ec-class-grades',
    templateUrl: './ec-class-grades.component.html',
    styleUrls: ['./ec-class-grades.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [ClassGradesService],
    imports: [MatTableModule, NgForOf],
    host: {
        class: 'ec-class-grades'
    }
})
export class EcClassGradesComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  @Input()
  set classroom(s: Classroom | null | undefined) {
    if (s) {
      this._grades.init(s);
    }
  }

  @Input() showTitles = true;

  columnsToDisplay: string[] = [];
  dataSource: MatTableDataSource<StudentGradesTableData>;

  constructor(private _grades: ClassGradesService) {
    this.dataSource = new MatTableDataSource<StudentGradesTableData>();

    combineLatest([
      this._grades.displayedColumns$,
      this._grades.studentGradesTableData$
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        ([displayedColumns, grades]: [string[], StudentGradesTableData[]]) => {
          this.columnsToDisplay = [...displayedColumns];
          this.dataSource.data = [...grades];

          // console.log(displayedColumns, grades);
        }
      );
  }

  ngOnDestroy() {
    this._grades.onDestroy();
    this._onDestroy$.next(true);
  }
}
