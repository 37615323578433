import {LiveAnnouncer} from '@angular/cdk/a11y';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  signal,
  Signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {
  AlgoliaPublishedQuiz,
  AlgoliaSearchSubjects,
  EC_ENVIRONMENT,
  ecQuizImageUploaderConfig,
  EvolutionCognitionEnvironment,
  getGradeLevel,
  GRADE_LEVELS,
  GradeLevel,
  Question,
  QUESTION_TYPE,
  QuestionAction,
  QuestionType,
  Quiz,
  QuizQueryEngine,
  QuizzesEngineStore,
  updateFirestorePartialQuiz
} from '@gigasoftware/evolving-cognition/domain';
import {
  GsAssetService,
  GSFirebaseUploadImageWithDocConfig,
  GsFirestoreUpdateDoc
} from '@gigasoftware/shared/media';
import {
  DlcMatInlineEditComponent,
  DlcInputImageComponent,
  DlcInputImageConfig,
  DlcLabelContainerComponent
} from '@gigasoftware/shared/ui-design-library';
import {WINDOW} from '@ngpat/utils';
import {Store} from '@ngrx/store';
import {from, Observable, Subject, switchMap} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {EcQuestionSummaryComponent} from './ec-question-summary/ec-question-summary.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-quiz-edit'
  },
  imports: [
    CommonModule,
    DlcInputImageComponent,
    DlcMatInlineEditComponent,
    EcQuestionSummaryComponent,
    DlcLabelContainerComponent,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [GsAssetService, AlgoliaSearchSubjects],
  selector: 'ec-quiz-edit',
  styleUrls: ['./ec-edit-quiz.component.scss'],
  templateUrl: './ec-edit-quiz.component.html'
})
export class EcEditQuizComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  aggregateFalseAnswers: FormControl<boolean | null> = new FormControl<boolean>(
    false
  );

  blobSignal: WritableSignal<Blob | null> = signal(null);

  @Output() doEditQuestion: EventEmitter<QuestionAction> =
    new EventEmitter<QuestionAction>();

  editQuizNameControl = new FormControl();

  filteredGradeLevels!: Observable<GradeLevel[]>;

  filteredSubjects!: Observable<AlgoliaPublishedQuiz[]>;

  gradeLevelControl = new FormControl();

  // GRADE LEVELS
  gradeLevels: GradeLevel[] = GRADE_LEVELS;

  imageChangedEvent: WritableSignal<Event | null> = signal(null);

  @ViewChild(DlcInputImageComponent) inputImageComponent:
    | DlcInputImageComponent
    | undefined;

  quizFirestoreDocPath: Signal<string | null | undefined> = toSignal(
    this.quizStore.selectCurrentQuizFirestoreDocPath$
  );

  inputImageConfig: Signal<DlcInputImageConfig | null | undefined> = computed(
    () => {
      const firestoreDocPath: string | null | undefined =
        this.quizFirestoreDocPath();

      if (firestoreDocPath) {
        return {
          filenameWithoutExtension: this.quiz()?.id || '',
          imagePath: this.quiz()?.imagePath || '',
          uploadConfig: <GSFirebaseUploadImageWithDocConfig>{
            ...ecQuizImageUploaderConfig,
            firestoreDoc: <GsFirestoreUpdateDoc>{
              docProperty: 'imagePath',
              firestoreDocPath
            }
          }
        };
      }

      return null;
    }
  );

  qe: Signal<QuizQueryEngine | null | undefined> = <
    Signal<QuizQueryEngine | null | undefined>
  >toSignal(this.quizStore.selectCurrentQuizQueryEngine$);

  questions: Signal<Question[]> = <Signal<Question[]>>(
    toSignal(this.quizStore.selectCurrentQuizQuestions$)
  );

  questionTypeDict: QuestionType = QUESTION_TYPE;
  quiz: Signal<Quiz | null | undefined> = <Signal<Quiz | null | undefined>>(
    toSignal(this.quizStore.selectCurrentQuiz$)
  );
  quizImagePath: Signal<string | null> = computed(() => {
    return this.quiz()?.imagePath || null;
  });

  // AUTOCOMPLETE FOR SUBJECTS
  separatorKeysCodes: number[] = [ENTER, COMMA];
  showID = false;
  subjectControl = new FormControl();
  @ViewChild('subjectInput') subjectInput!: ElementRef<HTMLInputElement>;

  constructor(
    private quizStore: QuizzesEngineStore,
    private store: Store,
    private cd: ChangeDetectorRef,
    private announcer: LiveAnnouncer,
    public algoliaSubjectAutocomplete: AlgoliaSearchSubjects,
    @Inject(WINDOW) private _win: Window,
    @Inject(EC_ENVIRONMENT) private environment: EvolutionCognitionEnvironment
  ) {
    effect(() => {
      const quiz: Quiz | undefined | null = this.quiz();

      if (quiz) {
        if (quiz.gradeLevel) {
          this.gradeLevelControl.setValue(getGradeLevel(quiz.gradeLevel), {
            emitEvent: false
          });
        }
        if (quiz.subjects) {
          this.algoliaSubjectAutocomplete.setManyCommaDelimited(quiz.subjects);
        }
        this.aggregateFalseAnswers.setValue(quiz.aggregateFalseAnswers, {
          emitEvent: false
        });
      }
    });
  }

  private _filterGradeLevels(name: string): GradeLevel[] {
    const filterValue = name.toLowerCase();

    return this.gradeLevels.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  // AUTOCOMPLETE FOR SUBJECTS
  /**
   * @param event
   */
  algoliaAutoCompleteAdd(event: MatChipInputEvent): void {
    this.algoliaSubjectAutocomplete.add(event, true);
    this.subjectControl.setValue(null);
  }

  /**
   * @param subject
   */
  algoliaAutoCompleteRemove(subject: string): void {
    const index = this.algoliaSubjectAutocomplete.remove(subject, true);

    if (index >= 0) {
      this.announcer.announce(`Removed ${subject}`);
    }
  }

  algoliaAutoCompleteSelect(event: MatAutocompleteSelectedEvent): void {
    this.algoliaSubjectAutocomplete.selected(event);
    this.subjectInput.nativeElement.value = '';
    this.subjectControl.setValue(null);
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  ngOnInit() {
    // GRADE LEVELS
    this.filteredGradeLevels = this.gradeLevelControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filterGradeLevels(name as string)
          : this.gradeLevels.slice();
      })
    );

    this.gradeLevelControl.valueChanges.subscribe((gradeLevel: GradeLevel) => {
      const quiz: Quiz | null | undefined = this.quiz();

      if (quiz) {
        this.store.dispatch(
          updateFirestorePartialQuiz({
            changes: <Quiz>{
              gradeLevel: gradeLevel.level
            },
            quiz
          })
        );
      }
    });

    this.aggregateFalseAnswers.valueChanges.subscribe(
      (aggregateFalseAnswers: boolean | null) => {
        const quiz: Quiz | null | undefined = this.quiz();

        if (quiz) {
          this.store.dispatch(
            updateFirestorePartialQuiz({
              changes: <Quiz>{
                aggregateFalseAnswers: aggregateFalseAnswers
              },
              quiz
            })
          );
        }
      }
    );

    // END GRADE LEVELS

    // AUTOCOMPLETE FOR SUBJECTS

    this.filteredSubjects = this.subjectControl.valueChanges.pipe(
      startWith(null),
      switchMap((subject: string) => {
        if (typeof subject === 'string' && subject && subject.length > 2) {
          return this.algoliaSubjectAutocomplete.search<AlgoliaPublishedQuiz>(
            subject
          );
        }
        return from([]);
      })
    );

    this.algoliaSubjectAutocomplete.valueChanges$.subscribe(
      (subjects: string) => {
        const quiz: Quiz | null | undefined = this.quiz();

        if (quiz) {
          this.algoliaSubjectAutocomplete.clear();

          this.store.dispatch(
            updateFirestorePartialQuiz({
              changes: <Quiz>{
                subjects: subjects
              },
              quiz
            })
          );
        }
      }
    );

    // END AUTOCOMPLETE FOR SUBJECTS

    this.cd.detectChanges();
  }

  // GRADE LEVELS

  onAssignQuiz(c: MatCheckboxChange) {
    const quiz: Quiz | null | undefined = this.quiz();

    if (quiz) {
      /**
       * TODO should this be called inside QuizQueryEngine?
       */
      this.store.dispatch(
        updateFirestorePartialQuiz({
          changes: <Quiz>{
            assigned: c.checked
          },
          quiz
        })
      );
    }
  }

  onDeleteImage() {
    const that = this;
    const quiz: Quiz | null | undefined = this.quiz();

    if (quiz) {
      that.store.dispatch(
        updateFirestorePartialQuiz({
          changes: <Quiz>{
            imagePath: null
          },
          quiz
        })
      );
    }
  }

  onEditQuizDescription(description: string) {
    const quiz: Quiz | null | undefined = this.quiz();

    if (quiz) {
      this.store.dispatch(
        updateFirestorePartialQuiz({
          changes: <Quiz>{
            description
          },
          quiz
        })
      );
    }
  }

  onEditQuizName(name: string) {
    const quiz: Quiz | null | undefined = this.quiz();

    if (quiz) {
      this.store.dispatch(
        updateFirestorePartialQuiz({
          changes: <Quiz>{
            name
          },
          quiz
        })
      );
    }
  }
  // END AUTOCOMPLETE FOR SUBJECTS
}
