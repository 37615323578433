<div class="flex flex-row justify-center items-center mb-4">
  <div class="aspect-square block w-full max-w-sm overflow-hidden">
    <dlc-input-image
      [config]="inputImageConfig()"
      (deleteImage)="onDeleteImage()"></dlc-input-image>
  </div>
</div>

<form [formGroup]="questionForm">
  <dlc-auto-resize-textarea
    class="mb-8"
    color="primary">
    <textarea
      formControlName="question"
      [placeholder]="questionPlaceholder"
      dlcAutoResizeTextArea
      autocomplete="off"></textarea>
  </dlc-auto-resize-textarea>

  <div class="mb-4">Multiple answers may be marked as correct.</div>

  <!-- ANSWERS -->
  <div formArrayName="answers">
    <div *ngFor="let answerFormGroup of answerFormGroups; let i = index">
      <form [formGroup]="answerFormGroup">
        <dlc-auto-resize-textarea
          class="mb-4"
          color="primary">
          <textarea
            formControlName="answerText"
            dlcAutoResizeTextArea
            placeholder="Enter Answer"></textarea>
          <dlc-auto-resize-textarea-controls>
            <!--            <button-->
            <!--              dlc-rounded-icon-button-->
            <!--              class="mr-4"-->
            <!--              (click)="clearAnswer(i)">-->
            <!--              <mat-icon>close</mat-icon>-->
            <!--            </button>-->
            <!--            <button-->
            <!--              dlc-rounded-icon-button-->
            <!--              class="mr-4"-->
            <!--              [disabled]="answerFormGroup.controls['answerText'].pristine"-->
            <!--              (click)="undoAnswer(i, answerFormGroup)">-->
            <!--              <mat-icon>undo</mat-icon>-->
            <!--            </button>-->
            <button
              dlc-rounded-icon-button
              class="mr-4"
              (click)="deleteAnswer(i)">
              <mat-icon>delete</mat-icon>
            </button>
            <div class="flex-auto"></div>
            <mat-checkbox
              color="primary"
              formControlName="isCorrect"></mat-checkbox>
          </dlc-auto-resize-textarea-controls>
        </dlc-auto-resize-textarea>
      </form>
    </div>

    <button
      dlc-rounded-text-icon-button
      (click)="addAnswerForm()"
      color="primary">
      <mat-icon>add</mat-icon>
      Add Answer
    </button>
  </div>
</form>

<!-- LINKS -->

<div class="mt-8 mb-4 flex flex-row justify-start items-start">
  <div>Add Reference Links to read more about this question.</div>
</div>
<div class="flex flex-col justify-start align-stretch">
  <ng-container *ngFor="let linkForm of linksService.forms; let i = index">
    <dlc-input-link
      class="mb-4"
      (deleteLink)="deleteLink(i)"
      [formGroup]="linkForm">
    </dlc-input-link>
  </ng-container>
</div>
<div>
  <button
    dlc-rounded-text-icon-button
    (click)="addLink()"
    color="primary">
    <mat-icon>add</mat-icon>
    Add Reference Link
  </button>
</div>
