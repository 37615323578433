<h3 mat-dialog-title>Confirm Delete {{ data.name }}</h3>
<mat-dialog-content>
  <p
    *ngIf="data?.message && !isDeleting()"
    class="project-name font-medium">
    {{ data.message }}
  </p>

  <div
    *ngIf="isDeleting()"
    class="flex flex-col justify-start items-start mb-8">
    <div class="mb-8">Deleting {{ data.entity.name }}</div>
    <mat-spinner
      class="self-center"
      mode="indeterminate"></mat-spinner>
  </div>

  <div
    *ngIf="hasDeleteError()"
    class="flex flex-col justify-start items-start mb-8">
    {{ deleteError() }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    (click)="dialogRef.close(false)"
    *ngIf="!isDeleting()"
    dlc-rounded-text-icon-button>
    <mat-icon>close</mat-icon>
    Cancel
  </button>
  <button
    (click)="onDeleteQuizAndAssets()"
    *ngIf="!isDeleting()"
    class="ml-4"
    color="warn"
    dlc-rounded-text-icon-button>
    <mat-icon>delete</mat-icon>
    Delete
  </button>
</mat-dialog-actions>
