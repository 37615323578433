import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
    selector: 'ui-mentor-subscription-text',
    templateUrl: './mentor-subscription-text.component.html',
    styleUrls: ['./mentor-subscription-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf]
})
export class MentorSubscriptionTextComponent implements OnInit {
  @Input() showTitle = false;
  constructor() {}

  ngOnInit(): void {}
}
