import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';

export interface ConfirmDelete {
  name: string;
  showRemove?: boolean;
  message?: string;
}

@Component({
    selector: 'ec-confirm-delete',
    templateUrl: './ec-confirm-delete-dialog.component.html',
    styleUrls: ['./ec-confirm-delete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DlcRoundedTextIconButtonComponent,
        MatDialogModule,
        MatIconModule
    ]
})
export class EcConfirmDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EcConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDelete
  ) {}
}
