import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {
  cloneInitialQuizTestProgress,
  QuizTestProgress
} from '@gigasoftware/evolving-cognition/domain';
import {
  EcQuestionProgressCounterComponent,
  EcCurrentGradeProgressComponent
} from '@gigasoftware/shared/ui-design-library';
import {EcQuizTestProgressComponent} from './ec-quiz-test-progress/ec-quiz-test-progress.component';

@Component({
    selector: 'ec-quiz-test-header',
    imports: [
        CommonModule,
        MatIconModule,
        EcQuizTestProgressComponent,
        EcQuestionProgressCounterComponent,
        EcCurrentGradeProgressComponent
    ],
    templateUrl: './ec-quiz-test-header.component.html',
    styleUrls: ['./ec-quiz-test-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ui-quiz-test-header'
    }
})
export class EcQuizTestHeaderComponent {
  progressSig: WritableSignal<QuizTestProgress> = signal(
    cloneInitialQuizTestProgress()
  );

  @Input()
  set progress(value: QuizTestProgress) {
    if (value) {
      this.progressSig.set(value);
    }
  }

  onSettingsClick() {
    console.log('settings clicked');
  }
}
