import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment
} from '@gigasoftware/evolving-cognition/domain';
import {DlcRoundedTextButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {
  DELETE_ACCOUNT_TYPE,
  PatDeleteAccountDialogComponent
} from './pat-delete-account-dialog/pat-delete-account-dialog.component';

@Component({
    selector: 'ec-delete-account',
    templateUrl: './pat-delete-account.component.html',
    styleUrls: ['./pat-delete-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DlcRoundedTextButtonComponent]
})
export class PatDeleteAccountComponent {
  constructor(
    private _dialog: MatDialog,
    @Inject(EC_ENVIRONMENT) private _env: EvolutionCognitionEnvironment
  ) {}

  onDeleteAccount() {
    const ref = this._dialog.open(PatDeleteAccountDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      data: this._env.isIosOrAndroid ? DELETE_ACCOUNT_TYPE.QUIZKIT_APP : DELETE_ACCOUNT_TYPE.BROWSER
    });

    ref.afterClosed().subscribe(() => {});
  }
}
