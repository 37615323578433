import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  ViewChild
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {
  createReviewQuizFromWrongAnswers,
  CreateReviewQuizFromWrongAnswers,
  getReferenceLinksFromQuestion,
  Question,
  Quiz,
  QuizResultService,
  QuizzesEngineStore,
  ResultAnswer,
  TakeQuizResult
} from '@gigasoftware/evolving-cognition/domain';
import {
  DlcImageDirective,
  DlcLabelContainerComponent,
  ReferenceLink
} from '@gigasoftware/shared/ui-design-library';
import {distinctUntilJsonChangedOperator} from '@ngpat/rxjs';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {
  ConfirmCreateReviewQuiz,
  EcConfirmCreateReviewQuizComponent
} from '../../dialogs';
import {EcReferenceLinkComponent} from '../ec-quiz-edit/ec-question-summary/ec-reference-link/ec-reference-link.component';

export interface QuizAndResultID {
  quiz: Quiz;
  resultID: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ec-quiz-review'
  },
  imports: [
    CommonModule,
    MatIconModule,
    DlcImageDirective,
    DlcLabelContainerComponent,
    EcReferenceLinkComponent
  ],
  providers: [QuizResultService],
  selector: 'ec-quiz-review',
  styleUrls: ['./ec-quiz-review.component.scss'],
  templateUrl: './ec-quiz-review.component.html'
})
export class EcQuizReviewComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  // qe: Signal<QuizQueryEngine | null | undefined> = computed(() => {
  //   return this.quizStore.selectedQuizQueryEngine();
  // });

  answers: Signal<ResultAnswer[]> = <Signal<ResultAnswer[]>>(
    toSignal(this.qr.answers$)
  );

  @Input() createReviewQuiz: Observable<void> = new Subject();

  // https://material.angular.io/components/table/examples
  dataSource: MatTableDataSource<ResultAnswer>;
  quiz: Signal<Quiz | null | undefined> = <Signal<Quiz | null | undefined>>(
    toSignal(this.quizStore.selectCurrentQuiz$)
  );

  @Output() reviewQuizCreated: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private quizStore: QuizzesEngineStore,
    public qr: QuizResultService,
    private _cd: ChangeDetectorRef,
    private store: Store,
    private _dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource<ResultAnswer>();

    this.quizStore.selectCurrentQuizResult$
      .pipe(distinctUntilJsonChangedOperator(), takeUntil(this._onDestroy$))
      .subscribe((result: TakeQuizResult | undefined | null) => {
        if (result) {
          this.qr.initialize(result);
        }
      });

    // effect(
    //   () => {
    //     const result: TakeQuizResult | undefined | null =
    //       this.qe()?.currentQuizResult();
    //
    //     if (result) {
    //       this.qr.initialize(result);
    //     }
    //   },
    //   {allowSignalWrites: true}
    // );
  }

  getReferenceLinksFromQuestion(question: Question): ReferenceLink[] {
    return getReferenceLinksFromQuestion(question);
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  ngOnInit(): void {
    this.createReviewQuiz
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(this.onCreateReviewQuiz.bind(this));
  }

  onCreateReviewQuiz() {
    const that = this;
    const quiz: Quiz | null | undefined = this.quiz();

    if (quiz) {
      this.qr.createQuizFromWrongAnswers$
        .pipe(take(1))
        .subscribe((r: CreateReviewQuizFromWrongAnswers) => {
          const dialogRef = this._dialog.open(
            EcConfirmCreateReviewQuizComponent,
            {
              backdropClass: 'dlc-global-dialog-background',
              data: <ConfirmCreateReviewQuiz>{
                quizName: quiz.name
              },
              panelClass: 'mat-typography'
            }
          );

          dialogRef.afterClosed().subscribe((createReviewQuiz: boolean) => {
            if (createReviewQuiz) {
              that.store.dispatch(
                createReviewQuizFromWrongAnswers({payload: r})
              );
              that.reviewQuizCreated.emit();
            }
          });
        });
    }
  }
}
