<form
  [formGroup]="classroomFormGroup"
  class="create-class-form-fields">
  <mat-form-field
    class="mb-8 w-full"
    appearance="outline">
    <mat-label>Class Name</mat-label>
    <input
      dlcInput
      formControlName="name"
      placeholder="Class name (required)" />
    <mat-error *ngIf="classroomFormGroup.get('name')?.hasError('required')">
      Required
    </mat-error>
  </mat-form-field>

  <mat-form-field
    class="mb-8 w-full"
    appearance="outline">
    <mat-label>Section</mat-label>
    <input
      dlcInput
      formControlName="section"
      placeholder="Section" />
  </mat-form-field>

  <mat-form-field
    class="mb-8 w-full"
    appearance="outline">
    <mat-label>Subject</mat-label>
    <input
      dlcInput
      formControlName="subject"
      placeholder="Subject" />
  </mat-form-field>

  <mat-form-field
    class="mb-8 w-full"
    appearance="outline">
    <mat-label>Room</mat-label>
    <input
      dlcInput
      formControlName="room"
      placeholder="Room" />
  </mat-form-field>
</form>

<button
  dlc-rounded-text-icon-button
  color="primary"
  [disabled]="classroomFormGroup.invalid"
  (click)="onSubmit()">
  CREATE
</button>
