import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'ui-quiz-answer-button, button[ui-quiz-answer-button]',
    imports: [CommonModule],
    templateUrl: './quiz-answer.component.html',
    styleUrls: ['./quiz-answer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'cssClass'
    }
})
export class QuizAnswerComponent {
  @Input() index = 1;

  get cssClass() {
    return `ui-quiz-answer-button ui-quiz-answer-button__${this.index}`;
  }
}
