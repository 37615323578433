import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  Signal,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {Answer} from '@gigasoftware/evolving-cognition/domain';

@Component({
    selector: 'ec-true-false-answer',
    imports: [CommonModule, MatIconModule],
    templateUrl: './ec-true-false-summary.component.html',
    styleUrls: ['./ec-true-false-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-true-false-answer'
    }
})
export class EcTrueFalseSummaryComponent {
  answerSignal: WritableSignal<Answer | null> = signal<Answer | null>(null);

  isTrue: Signal<boolean> = computed(() => {
    return this.answerSignal()?.trueFalseAnswer === true;
  });

  @Input()
  set answer(value: Answer | null) {
    this.answerSignal.set(value);
  }
}
