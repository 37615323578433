import {CommonModule, formatDate} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {
  BaseEntity,
  firestoreQuizGradeByEntity,
  QuizGrade,
  QuizGradesCreatorService,
  QuizzesEngineStore,
  TakeQuizResult
} from '@gigasoftware/evolving-cognition/domain';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {
  NgPatBulletChartComponent,
  NgPatBulletChartConfig
} from '@ngpat/charts/bullet-chart';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {User} from 'firebase/auth';
import {take} from 'rxjs/operators';
import {EcConfirmDeleteDialogComponent} from '../../dialogs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ec-grades'
    },
    imports: [
        CommonModule,
        NgPatBulletChartComponent,
        DlcRoundedTextIconButtonComponent,
        MatDividerModule,
        MatProgressBarModule,
        MatIconModule
    ],
    providers: [QuizGradesCreatorService],
    selector: 'ec-grades',
    styleUrls: ['./ec-quiz-grades.component.scss'],
    templateUrl: './ec-quiz-grades.component.html'
})
export class EcQuizGradesComponent {
  bulletChartConfig: NgPatBulletChartConfig = {
    description: 'bar',
    // tooltipWidth: 62,
    // tooltipReversed: true,
    title: 'foo',
    tooltipConfig: {
      height: 45,
      hover: false,
      reversed: true,
      rx: 4,
      visible: true,
      width: 62
    }
  };

  // qe: Signal<QuizQueryEngine | null | undefined> = computed(() => {
  //   return this.quizStore.selectedQuizQueryEngine();
  // });

  grades: Signal<QuizGrade<TakeQuizResult>[]> = <
    Signal<QuizGrade<TakeQuizResult>[]>
  >toSignal(this.quizStore.selectCurrentQuizGrades$);

  showProgressBar = false;

  // @Input()
  // set quiz(q: Quiz | undefined | null) {
  //   if (q) {
  //     this.quiz$.next(q);
  //     this.grades.init(q);
  //   }
  // }

  @Input() showTitles = true;

  @Output() reviewAction: EventEmitter<TakeQuizResult> =
    new EventEmitter<TakeQuizResult>();

  constructor(
    private _cd: ChangeDetectorRef,
    private custom: NgPatFirestoreService,
    private _dialog: MatDialog,
    private quizStore: QuizzesEngineStore
  ) {}

  delete(grade: QuizGrade<TakeQuizResult>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    const ref = this._dialog.open(EcConfirmDeleteDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      data: {
        name: grade?.result?.timestamp
          ? formatDate(grade.result.timestamp, 'medium', 'en-US')
          : ''
      }
    });

    ref.afterClosed().subscribe((doDelete: boolean) => {
      if (doDelete) {
        that.custom.user$.pipe(take(1)).subscribe((user: User) => {
          if (user.uid) {
            that.showProgressBar = true;
            that._cd.detectChanges();
            const path = firestoreQuizGradeByEntity(
              grade.result.quiz as BaseEntity,
              user.uid,
              grade.result.id
            );
            that.custom.recursiveDelete$(path).subscribe(
              () => {
                that.showProgressBar = false;
                that._cd.detectChanges();
              },
              () => {
                that.showProgressBar = false;
                that._cd.detectChanges();
              }
            );
          }
        });
      }
    });
  }
}
