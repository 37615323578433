import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

export interface ConfirmLeave {
  name: string;
  showRemove?: boolean;
  message?: string;
}

@Component({
    // 'plat-confirm-leave-dialog'
    selector: 'ui-confirm-leave-dialog',
    templateUrl: './plat-confirm-leave-dialog.component.html',
    styleUrls: ['./plat-confirm-leave-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, NgIf, MatButtonModule]
})
export class PlatConfirmLeaveDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PlatConfirmLeaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmLeave
  ) {}

  ngOnInit(): void {}
}
