import {Clipboard} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip';
import {Route} from '@angular/router';
import {
  DlcHeaderBarComponent,
  DlcMobilePageContentComponent,
  fadeInAnimation
} from '@gigasoftware/shared/ui-design-library';

@Component({
    selector: 'ec-support-small-page',
    templateUrl: './ec-support-small-page.component.html',
    styleUrls: ['./ec-support-small-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[@fadeInAnimation]': '',
        class: 'ec-support-small-page dlc-global-mobile-page'
    },
    animations: [fadeInAnimation],
    imports: [
        DlcHeaderBarComponent,
        DlcMobilePageContentComponent,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule
    ]
})
export class EcSupportSmallPageComponent {
  email = `support@gigasoftware.io`;

  @ViewChild('copyTooltip', {static: true}) copyTooltip!: MatTooltip;

  constructor(private clipboard: Clipboard) {}

  copyEmail() {
    this.clipboard.copy(this.email);

    if (this.copyTooltip) {
      this.copyTooltip.show();
    }
  }
}

export const EC_SUPPORT_ROUTES: Route[] = [
  {path: '', pathMatch: 'full', component: EcSupportSmallPageComponent}
];
