import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CollaborativeEntity, Quiz, QuizzesEngineStore} from '@gigasoftware/evolving-cognition/domain';
import {EcQuizListItemComponent} from './ec-quiz-list-item/ec-quiz-list-item.component';

@Component({
    selector: 'ec-quiz-list',
    imports: [CommonModule, MatProgressBarModule, EcQuizListItemComponent],
    templateUrl: './ec-quiz-list.component.html',
    styleUrls: ['./ec-quiz-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ec-quiz-list'
    }
})
export class EcQuizListComponent {
  quizzes: WritableSignal<string[]> = signal([]);

  @Input()
  set list(list: string[]) {
    this.quizzes.set(list);
  }

  @Output() doSelectItem: EventEmitter<CollaborativeEntity> = new EventEmitter<CollaborativeEntity>();
  @Output() doTakeQuiz: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  @Output() doSeeAllGrades: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  @Output() doEditQuiz: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  @Output() doStatus: EventEmitter<Quiz> = new EventEmitter<Quiz>();

  constructor(private quizStore: QuizzesEngineStore) {}
}
