import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {
  UntypedFormControl,
  Validators,
  ReactiveFormsModule
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {ngPatAddMonitorAccount} from '@ngpat/store';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
    //'pat-plat-url-accounts'
    selector: 'ui-url-accounts',
    templateUrl: './plat-link-accounts.component.html',
    styleUrls: ['./plat-link-accounts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        NgIf,
        MatButtonModule,
        MatIconModule
    ]
})
export class PlatLinkAccountsComponent {
  linkForm: UntypedFormControl = new UntypedFormControl(null, [
    Validators.required
  ]);

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<PlatLinkAccountsComponent>
  ) {}

  linkAccount() {
    if (this.linkForm.valid) {
      this.store.dispatch(ngPatAddMonitorAccount({code: this.linkForm.value}));
      this.dialogRef.close(true);
    }
  }
}
