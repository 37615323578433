import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReferenceLink} from '@gigasoftware/shared/ui-design-library';
import {WindowService} from '@ngpat/utils';

@Component({
    selector: 'ec-reference-link',
    imports: [CommonModule],
    templateUrl: './ec-reference-link.component.html',
    styleUrls: ['./ec-reference-link.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ec-reference-link'
    }
})
export class EcReferenceLinkComponent {
  @Input() links: ReferenceLink[] = [];

  constructor(private win: WindowService) {}

  openLink(event: Event, referenceLink: ReferenceLink) {
    event.preventDefault();
    event.stopPropagation();
    this.win.open(referenceLink.url);
  }
}
