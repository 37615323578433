import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {Answer, QuestionWithAnswer, UserAnswer} from '@gigasoftware/evolving-cognition/domain';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule
} from '@angular/forms';
import {shuffle} from '@ngpat/fn';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule} from '@angular/common';
import {QuizAnswerComponent} from '../../../button/quiz-answer/quiz-answer.component';

interface MultiChoiceAnswer {
  checked: boolean;
  answer: Answer;
}

interface MultiChoiceForm {
  formArray: MultiChoiceAnswer[];
}

@Component({
    selector: 'ec-answer-multiple-choice',
    templateUrl: './ec-answer-multiple-choice.component.html',
    styleUrls: ['../answers.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        QuizAnswerComponent,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule
    ],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-answer-multiple-choice answer__container'
    }
})
export class EcAnswerMultipleChoiceComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  question$: ReplaySubject<QuestionWithAnswer> = new ReplaySubject<QuestionWithAnswer>(1);

  recordedAnswer: QuestionWithAnswer | null = null;

  @Input()
  set question(q: QuestionWithAnswer | null | undefined) {
    // console.log(q);
    if (q) {
      this._onDestroy$.next(true);
      this.question$.next(q);
      this.init(q);
    }
  }

  @Output() answer: EventEmitter<QuestionWithAnswer> = new EventEmitter<QuestionWithAnswer>();

  questionForm: FormGroup;

  get formArray() {
    return this.questionForm.get('formArray') as FormArray;
  }

  get answerChoices() {
    return this.formArray.controls as FormGroup[];
  }

  hasMultipleAnswers: WritableSignal<boolean> = signal(false);

  constructor(private _fb: FormBuilder) {
    this.questionForm = this._fb.group({
      formArray: new FormArray([])
    });
  }

  init(question: QuestionWithAnswer): void {
    let answers: Answer[] = [];

    /**
     * Collect all answers
     */
    for (const key in question.question.answers) {
      answers.push(question.question.answers[key]);
    }

    const numberCorrectAnswers: number = answers.reduce<number>((acc: number, i: Answer) => {
      if (i.isCorrect) {
        return acc + 1;
      }

      return acc;
    }, 0);

    if (
      numberCorrectAnswers !== null &&
      numberCorrectAnswers !== undefined &&
      numberCorrectAnswers > 1
    ) {
      this.hasMultipleAnswers.set(true);
    }

    /**
     * Randomize order of answers
     */
    if (answers.length > 2) {
      answers = shuffle(answers);
    }

    this.formArray.clear({emitEvent: false});

    for (let i = 0; i < answers.length; i++) {
      this.formArray.push(
        this._fb.group({
          checked: new FormControl(answers[i].trueFalseAnswer),
          answer: new FormControl(answers[i])
        })
      );
    }

    this.questionForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((r: MultiChoiceForm) => {
        const allCorrect: boolean = r.formArray.reduce(
          (allCorrect: boolean, i: MultiChoiceAnswer) => {
            if (allCorrect) {
              if (i.checked === null || i.checked === undefined) {
                return !i.answer.isCorrect;
              } else {
                return i.checked === i.answer.isCorrect;
              }
            }
            return allCorrect;
          },
          true
        );

        const multiChoiceAnswers: {[answerID: string]: UserAnswer} = r.formArray.reduce(
          (d: {[answerID: string]: UserAnswer}, i: MultiChoiceAnswer) => {
            d[i.answer.id] = {
              answer: i.answer,
              trueFalseAnswer: false,
              multipleChoiceChecked: i.checked
            };

            return d;
          },
          {}
        );

        this.recordedAnswer = {
          ...question,
          answers: multiChoiceAnswers,
          isCorrect: allCorrect,
          isAnswered: true
        };

        // this.answer.emit(this.recordedAnswer);
      });
  }

  toggleCheckbox(i: number) {
    this.formArray?.at(i).get('checked')?.setValue(!this.formArray?.at(i)?.get('checked')?.value);
  }

  submitAnswer() {
    if (this.recordedAnswer) {
      this.answer.emit(this.recordedAnswer);
    }
  }

  getAnswerText(index: number) {
    const formControl: AbstractControl | null = (<FormGroup>this.formArray.at(index)).get('answer');
    return formControl ? formControl.value.answerText : '';
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
