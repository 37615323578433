import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
    selector: 'ui-student-subscription-text',
    templateUrl: './student-subscription-text.component.html',
    styleUrls: ['./student-subscription-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf]
})
export class StudentSubscriptionTextComponent implements OnInit {
  @Input() showTitle = false;

  constructor() {}

  ngOnInit(): void {}
}
