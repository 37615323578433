<div class="flex flex-row justify-center items-center mb-4">
  <div class="aspect-square block w-full max-w-sm overflow-hidden">
    <dlc-input-image
      [config]="inputImageConfig()"
      (deleteImage)="onDeleteImage()"></dlc-input-image>
  </div>
</div>

<form [formGroup]="questionForm">
  <dlc-auto-resize-textarea
    class="mb-8"
    color="primary">
    <textarea
      formControlName="question"
      [placeholder]="questionPlaceholder"
      dlcAutoResizeTextArea
      autocomplete="off"></textarea>
  </dlc-auto-resize-textarea>

  <div class="mb-4 answer">
    <div class="mat-h4">Select Correct Answer</div>
    <mat-radio-group
      color="primary"
      aria-label="Answer"
      formControlName="trueFalseAnswer">
      <mat-radio-button
        value="true"
        [checked]="questionForm.controls['trueFalseAnswer'].value === true"
        >True</mat-radio-button
      >
      <mat-radio-button
        value="false"
        [checked]="questionForm.controls['trueFalseAnswer'].value === false"
        >False</mat-radio-button
      >
    </mat-radio-group>
  </div>
</form>

<!-- LINKS -->

<div class="mt-8 mb-4 flex flex-row justify-start items-start">
  <div>Add Reference Links to read more about this question.</div>
</div>
<div class="flex flex-col justify-start align-stretch">
  <ng-container *ngFor="let linkForm of linksService.forms; let i = index">
    <dlc-input-link
      class="mb-4"
      (deleteLink)="deleteLink(i)"
      [formGroup]="linkForm">
    </dlc-input-link>
  </ng-container>
</div>
<div>
  <button
    dlc-rounded-text-icon-button
    (click)="addLink()"
    color="primary">
    <mat-icon>add</mat-icon>
    Add Reference Link
  </button>
</div>
