<div class="flex flex-row justify-center items-center">
  <div class="aspect-square block w-full max-w-sm overflow-hidden">
    <dlc-input-image
      [config]="inputImageConfig()"
      (deleteImage)="onDeleteImage()"></dlc-input-image>
  </div>
</div>

<form
  [formGroup]="classroomFormGroup"
  class="relative flex flex-col justify-start items-stretch mt-8">
  <mat-form-field class="w-full">
    <mat-label>Class Name</mat-label>
    <input
      matInput
      formControlName="name"
      placeholder="Class name (required)" />
    <mat-error *ngIf="classroomFormGroup.get('name')?.hasError('required')">
      Required
    </mat-error>
  </mat-form-field>

  <mat-form-field class="mt-4 w-full">
    <mat-label>Section</mat-label>
    <input
      matInput
      formControlName="section"
      placeholder="Section" />
  </mat-form-field>

  <mat-form-field class="mt-4 w-full">
    <mat-label>Subject</mat-label>
    <input
      matInput
      formControlName="subject"
      placeholder="Subject" />
  </mat-form-field>

  <mat-form-field class="mt-4 w-full">
    <mat-label>Room</mat-label>
    <input
      matInput
      formControlName="room"
      placeholder="Room" />
  </mat-form-field>
</form>
<div class="flex flex-row justify-start items-start mt-8">
  <button
    dlc-rounded-text-icon-button
    color="primary"
    class="mr-4"
    (click)="resetNameForm()"
    [disabled]="classroomFormGroup.pristine">
    <mat-icon>undo</mat-icon>
    Undo
  </button>

  <button
    dlc-rounded-text-icon-button
    color="primary"
    (click)="saveForm()"
    [disabled]="classroomFormGroup.pristine || classroomFormGroup.invalid">
    <mat-icon>check</mat-icon>
    Save
  </button>
</div>
